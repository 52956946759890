import React from 'react'
import styles from "./Footer.module.scss"

export const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className='container'>
                <span>© Հեղինակային իրավունք Կառնուտ Շաուրմա. Բոլոր իրավունքները պաշտպանված են: 2024</span>
                <span>+374 94 123456</span>
            </div>
        </footer>
    )
}
