import React from 'react'
import styles from "./Services.module.scss"

export const Services = () => {
    return (
        <div className={`${styles.services} container`} id='services'>
            <div className={styles.services_el}>
                <img src="/images/order.png" alt="" />
                <h4>Հեշտ Է Պատվիրել</h4>
                <span>Սնունդ պատվիրելը պարզապես մի քանի քայլով</span>
            </div>
            <div className={styles.services_el}>
                <img src="/images/delivery.png" alt="" />
                <h4>Արագ Առաքում</h4>
                <span>Պատվերը Ձեզ կհասնի ամենակարճ ժամկետում</span>
            </div>
            <div className={styles.services_el}>
                <img src="/images/quality.png" alt="" />
                <h4>Բարձր Որակ</h4>
                <span>Միշտ Թարմ Համեղ և Բարձրորակ մթերք</span>
            </div>
        </div>
    )
}
