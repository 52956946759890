import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import styles from "./Header.module.scss"
import { Button } from '../../ui/Button/Button'
import { Basket } from '../../ui/Basket/Basket'

export const Header = ({ basket }) => {
    const navigate = useNavigate();
    const gotoFragment = (fragment) => {
        setTimeout(() => {
            const element = document.getElementById(fragment);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 0);
    };


    return (
        <div className={`${styles.header} container`}>
            <div className={styles.logo}>
                <img src="/logo192.png" alt="" />
                <h4>Կառնուտ շաուրմա</h4>
            </div>
            <div className={styles.navs}>
                <Button onClick={() => gotoFragment('welcome')} variant='link'>Բարի գալուստ</Button>
                <Button onClick={() => gotoFragment('menu')} variant='link'>Մենյու</Button>
                <Button onClick={() => gotoFragment('services')} variant='link'>Ծառայություններ</Button>
                <Button onClick={() => gotoFragment('contact')} variant='link'>Կապ</Button>
            </div>
            <div className={styles.basket}><Basket products={basket} /></div>
        </div>
    )
}
