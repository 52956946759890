import React from "react";
import styles from "./Button.module.scss";

export const Button = ({
    size = "medium",
    variant = "primary",
    disabled = false,
    onClick,
    children,
    icon,
    type = "button"
}) => {
    const buttonClassNames = `
    ${styles.btn} 
    ${styles[size]} 
    ${styles[variant]} 
    ${disabled ? styles.disabled : ""}
  `;

    return (
        <button
            className={buttonClassNames}
            onClick={!disabled ? onClick : undefined}
            disabled={disabled}
            type={type}
        >
            {icon === "left" && <div className={styles.icon}>
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fillRule="evenodd" clipRule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM10.6935 15.8458L15.4137 13.059C16.1954 12.5974 16.1954 11.4026 15.4137 10.941L10.6935 8.15419C9.93371 7.70561 9 8.28947 9 9.21316V14.7868C9 15.7105 9.93371 16.2944 10.6935 15.8458Z" fill="#ee6e26"></path> </g></svg>
            </div>}
            <span className={styles.text}>{children}</span>
            {icon === "right" && <div className={styles.icon}></div>}
        </button>
    );
};