import React from 'react'
import styles from "./FoodCard.module.scss"
import { Button } from '../Button/Button'

export const FoodCard = ({ food, addToBasket }) => {
    return (
        <div className={styles.card}>
            <img src={`/menu/${food.image}`} alt="" />
            <h5>{food.name}</h5>
            <span>{food.description}</span>
            <h6>{food.price}֏</h6>
            <Button onClick={() => addToBasket(food)} size='small'>Ավելացնել զամբյուղ</Button>
        </div>
    )
}
