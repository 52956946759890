import React from 'react'
import { FoodCard } from '../../ui/FoodCard/FoodCard'
import styles from "./Menu.module.scss"

export const Menu = ({ addToBasket }) => {
  const menu = [{
    image: "lavash.jpg",
    name: "Խոզի շաուրմա լավաշով",
    price: 1000,
    description: "",
    id: 1
  }, {
    image: "hachav.jpg",
    name: "Հավի շաուրմա հացով",
    price: 700,
    description: "",
    id: 2
  }, {
    image: "hachav.jpg",
    name: "Հավի շաուրմա հացով",
    price: 700,
    description: "",
    id: 3
  }, {
    image: "tomat.jpg",
    name: "Տոմատի սոկ 250մլ",
    price: 250,
    description: "",
    id: 4
  }, {
    image: "tan.jpg",
    name: "Թան 250մլ",
    price: 250,
    description: "",
    id: 5
  }, {
    image: "chay.jpg",
    name: "Թեյ 250մլ",
    price: 250,
    description: "",
    id: 6
  }, {
    image: "kompot.jpg",
    name: "Կոմպոտ 250մլ",
    price: 250,
    description: "",
    id: 7
  }, {
    image: "tomatspicy.jpg",
    name: "Տոմատի սոկ կծու 250մլ",
    price: 250,
    description: "",
    id: 8
  }, {
    image: "masur.jpg",
    name: "Մասուր 250մլ",
    price: 250,
    description: "",
    id: 9
  }, {
    image: "kofe.jpg",
    name: "Սառը սուրճ",
    price: 200,
    description: "",
    id: 10
  }]
  return (<div className={`${styles.menu} container`} id='menu'>
    <h3>Մենյու</h3>
    <div className={`${styles.menu_list}`}>
      {menu.map((food, i) => {
        return <FoodCard food={food} key={i} addToBasket={addToBasket} />
      })}
    </div></div>
  )
}
