import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import './App.scss';
import { Main } from "./pages/Main/Main";
import { Checkout } from "./pages/Checkout/Checkout";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to={`/main`} />} />
          <Route path="/main" element={<Main />} />
          <Route path="/checkout" element={<Checkout />} />
          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
