import React, { useState } from 'react'
import styles from "./Main.module.scss"
import { Header } from '../../components/Header/Header'
import { Welcome } from '../../components/Welcome/Welcome'
import { Menu } from '../../components/Menu/Menu'
import { Contact } from '../../components/Contact/Contact'
import { Services } from '../../components/Services/Services'
import { Footer } from '../../components/Footer/Footer'

export const Main = () => {
    const [basket, setBasket] = useState([])
    const addToBasket = (food) => {
        setBasket((prevBasket) => {
            const existingItem = prevBasket.find((item) => item.id === food.id);
            if (existingItem) {
                return prevBasket.map((item) =>
                    item.id === food.id ? { ...item, count: item.count + 1 } : item
                );
            } else {
                return [...prevBasket, { ...food, count: 1 }];
            }
        });
    }

    return (
        <div>
            <Header basket={basket} />
            <Welcome />
            <Menu addToBasket={addToBasket} />
            <Services />
            <Contact />
            <Footer />
        </div>
    )
}
