import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import styles from "./CheckoutForm.module.scss"
import { Button } from '../../ui/Button/Button';

export const CheckoutForm = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        console.log('Order Data:', data);
        // Simulate an API call or any async operation
        setTimeout(() => {
            alert('Order Submitted Successfully!');
            reset();
            setIsSubmitting(false);
        }, 1500);
    };

    return (
        <div className={`container ${styles.checkout}`}>
            <form className={styles.checkout_form} onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.checkout_form_group}>
                    <label htmlFor="name">Անուն Ազգանուն</label>
                    <input
                        type="text"
                        id="name"
                        {...register('name', { required: 'Անուն Ազգանուն պարտադիր է լրացնել' })}
                    />
                    {errors.name && <p className="error">{errors.name.message}</p>}
                </div>

                <div className={styles.checkout_form_group}>

                    <label htmlFor="phone">Հեռախոսահամար</label>
                    <input
                        type="text"
                        id="phone"
                        {...register('phone', { required: 'Հեռախոսահամարը պարտադիր է լրացնել' })}
                    />
                    {errors.phone && <p className="error">{errors.phone.message}</p>}
                </div>

                <div className={styles.checkout_form_group}>

                    <label htmlFor="address">Հասցե</label>
                    <input
                        type="text"
                        id="address"
                        {...register('address', {
                            required: 'Հասցեն պարտադիր է լրացնել',
                        })}
                    />
                    {errors.address && <p className="error">{errors.address.message}</p>}
                </div>

                <div className={styles.checkout_form_group}>

                    <label htmlFor="orderDetails">Պատվերի մանրամասներ</label>
                    <textarea
                        id="orderDetails"
                        {...register('orderDetails')}
                    ></textarea>
                    {errors.orderDetails && <p className="error">{errors.orderDetails.message}</p>}
                </div>

                <Button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? 'Submitting...' : 'Հաստատել պատվերը'}
                </Button>
            </form>
            {/* <div className={styles.checkout_basket}></div> */}
        </div>
    );
};
