import React from 'react'
import { Header } from '../../components/Header/Header'
import { Footer } from '../../components/Footer/Footer'
import { Contact } from '../../components/Contact/Contact'
import { CheckoutForm } from './../../components/CheckoutForm/CheckoutForm';

export const Checkout = () => {
    const basket = JSON.parse(localStorage.getItem("basket"))

    return (
        <div>
            <Header basket={basket} />
            <CheckoutForm />
            <Contact />
            <Footer />
        </div>
    )
}
